<template>
  <div class="brandDetail">
    <div class="box">
      <div class="card left">
        <div class="item align">
          <img class="logo" :src="ossUrl(submitForm.authentication.brandCertification.brandLogoPic)">
          <span>{{submitForm.authentication.brandCertification.brandName}}</span>
        </div>
        <div class="item">
          <span class="label">简介</span>
          <span class="value">{{submitForm.business.describe}}</span>
        </div>
        <div class="item">
          <span class="label">品牌主营</span>
          <span class="value">{{submitForm.business.brandMerito}}</span>
        </div>
        <div class="item">
          <span class="label">品牌故事</span>
          <span class="value">{{submitForm.authentication.introduction}}</span>
        </div>
      </div>
      <div class="card right">
        <h2>绑定管理员</h2>
        <div class="item align">
          <img class="logo" :src="submitForm.personalResp.headImage">
          <div>
            <div>{{submitForm.personalResp.identityName}}</div>
            <div>{{submitForm.personalResp.sex===0?'女':'男'}}</div>
            <div>{{submitForm.personalResp.phone}}</div>
          </div>
        </div>
        <div class="item">
          <span class="label">最后IP属地</span>
          <span class="value">{{submitForm.business.ipLocation}}</span>
        </div>
        <div class="item">
          <span class="label">品牌最后登录时间</span>
          <span class="value">{{submitForm.business.lastLoginTime}}</span>
        </div>
        <div class="item">
          <span class="label">品牌入驻时间</span>
          <span class="value">{{submitForm.business.createdTime}}</span>
        </div>
      </div>
    </div>
    <div class="card card2 mt24">
      <div class="imageBox">
        <h3 class="MB20">商标注册证</h3>
        <HKImage emptyText="商标注册证图" :src="ossUrl(submitForm.authentication.brandCertification.brandRegistPic)" />
      </div>
      <div class="imageBox">
        <h3 class="MB20">商标授权书</h3>
        <HKImage emptyText="商标授权书" :src="ossUrl(submitForm.authentication.brandCertification.brandAuthorizePic)" />
      </div>
      <div class="imageBox">
        <h3 class="MB20">主页背景图</h3>
        <HKImage emptyText="主页背景图" :src="ossUrl(submitForm.business.bgm)" />
      </div>
    </div>
    <div class="card card3 mt24">
      <h3 class="MB20">品牌资质实力</h3>
      <div class="imageBox">
        <HKImage class="MR20" :emptyText="`资质实力图${index=1}`" v-for="(item,index) in submitForm.authentication.abilityCertification" :key="index" :src="ossUrl(item)" />
      </div>
    </div>
    <div class="card card4 mt24">
      <h3 class="MB20">品牌企业信息</h3>
      <div class="item">
        <span class="label">企业名称</span>
        <span class="value">{{ submitForm.realBusiCertification.enterpriseName }}</span>
      </div>
      <div class="item block">
        <span class="label">营业执照</span>
        <div class="value MT10">
          <HKImage emptyText="营业执照图" :src="ossUrl(submitForm.realBusiCertification.busiLicensePic)" />
        </div>
      </div>
      <div class="item">
        <span class="label">社会统一信用代码</span>
        <span class="value">{{ submitForm.realBusiCertification.busiLicenseCode }}</span>
      </div>
      <div class="item">
        <span class="label">营业执照有效期</span>
        <span class="value">{{ submitForm.realBusiCertification.busiLicenseAvailable }}</span>
      </div>
      <div class="item">
        <span class="label">法人姓名</span>
        <span class="value">{{ submitForm.realBusiCertification.legalmanLicenceName }}</span>
      </div>
      <div class="item block">
        <span class="label">法人身份证</span>
        <div class="imageBox MT10">
          <HKImage emptyText="手持身份证照片" :src="ossUrl(submitForm.realBusiCertification.legalmanLicenceHandpic)" />
          <HKImage class="ml24" emptyText="身份证正面照片" :src="ossUrl(submitForm.realBusiCertification.legalmanLicencePic)" />
          <HKImage class="ml24" emptyText="身份证反面照片" :src="ossUrl(submitForm.realBusiCertification.legalmanLicenceBackpic)" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import BrandManageModel from '@/api/brandManage'
import HKImage from '@/components/HKImage'
export default {
  components: { HKImage },
  data() {
    return {
      submitForm: {
        business: {
          id: 0,
          userId: '',
          businessTag: 0,
          businessType: 0,
          companyName: '',
          shopName: '',
          applyState: 0,
          describe: '',
          identityName: '',
          identityState: 0,
          state: 0,
          lastLoginIp: '',
          lastLoginTime: '',
          ipLocation: '',
          createdBy: '',
          updatedBy: '',
          createdTime: '',
          updatedTime: '',
          validFlag: 0,
          comment: '',
          logo: '',
          personalResp: {
            phone: '',
            name: '',
            headImage: ''
          },
          fansNum: 0,
          follow: 0,
          favorite: 0,
          brandName: '',
          brandLogoPic: '',
          businessReal: 0,
          bindShopNum: 0
        },
        personalResp: {
          userId: '',
          businessUserId: '',
          headImage: '',
          phone: '',
          name: '',
          sex: 0,
          birthday: '',
          realName: '',
          idNumber: '',
          frontIdentityCard: '',
          backIdentityCard: '',
          handIdentityCard: '',
          identityName: '',
          lastLoginIp: '',
          lastLoginTime: '',
          createdTime: '',
          ipLocation: '',
          userTabs: [''],
          state: 0,
          like: 0,
          favorite: 0,
          follow: 0,
          real: 0,
          issueImageNum: 0,
          issueVideoNum: 0,
          issueWorksNum: 0,
          issueArticleNum: 0
        },
        realNameCertification: {
          name: '',
          idCardUrl: [''],
          frontIdentityCard: '',
          backIdentityCard: '',
          handIdentityCard: ''
        },
        realBusiCertification: {
          enterpriseName: '',
          enterpriseState: 0,
          busiLicensePic: '',
          busiLicenseCode: '',
          busiLicenseAvailable: '',
          legalmanLicenceName: '',
          legalmanLicenceCode: '',
          legalmanLicencePic: '',
          legalmanLicenceBackpic: '',
          legalmanLicenceHandpic: ''
        },
        authentication: {
          introduction: '',
          brandCertification: {
            brandRegistPic: '',
            brandName: '',
            brandLogoPic: '',
            brandAuthorizePic: ''
          },
          addressCertification: {
            shopAdress: '',
            shopAdressDetail: '',
            workDaysBegin: '',
            workDaysEnd: '',
            workTimeBegin: '',
            workTimeEnd: ''
          },
          officeEnvironmentCertification: [''],
          certification: {
            certificationMsg: '',
            certificationImg: ['']
          },
          experienceCertifications: [
            {
              userId: '',
              type: 0,
              timePeriod: '',
              title: '',
              exhibitionHall: '',
              adress: '',
              order: '',
              experiencePics: ['']
            }
          ],
          awardCertifications: [
            {
              userId: '',
              type: 0,
              timePeriod: '',
              title: '',
              exhibitionHall: '',
              adress: '',
              order: '',
              experiencePics: ['']
            }
          ],
          exhibitionCertifications: [
            {
              userId: '',
              type: 0,
              timePeriod: '',
              title: '',
              exhibitionHall: '',
              adress: '',
              order: '',
              experiencePics: ['']
            }
          ],
          abilityCertification: []
        }
      }
    }
  },
  mounted() {
    this.iitData()
  },
  methods: {
    iitData() {
      let userId = this.$route.query.userId
      if (!userId) return
      BrandManageModel.detail({ userId }).then((res) => {
        this.submitForm = res.data
      })
    }
  }
}
</script>
<style lang="less" scoped>
.brandDetail {
  padding: 20px;
  background: #fff;

  .card {
    width: 100%;
    padding: 20px;
    border: 1px solid rgb(235, 237, 240);

    h3 {
      font-weight: bold;
    }

    .item {
      display: flex;

      margin-bottom: 20px;

      .logo {
        width: 60px;
        height: 60px;
      }

      .label {
        display: block;
        width: 100px;
        font-weight: 500;
      }

      .value {
        flex: 1;
        color: #00000085;
      }
    }
    .align {
      align-items: center;
    }
    .block {
      display: block;
    }
  }

  .box {
    display: flex;

    .left {
      flex: 2;
      margin-right: 20px;
    }

    .right {
      flex: 1;

      h2 {
        font-weight: bold;
      }

      .label {
        width: 140px;
      }
    }
  }

  .card2 {
    display: flex;

    .imageBox {
      margin-right: 20px;
    }
  }

  .card3 {
    .imageBox {
      display: flex;
    }
  }

  .card4 {
    .item {
      .label {
        width: 140px;
      }
    }
    .imageBox {
      display: flex;
      flex-wrap: wrap;
    }
  }
}
</style>
